//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

@import "../node_modules/react-grid-layout/css/styles.css";
@import "../node_modules/react-resizable/css/styles.css";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";

.accordian-bg-products {
	background-color: $gray-300;
}

.nav-link {
	cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
	float: right;

	.pagination {
		.page-link {
			border-radius: 30px !important;
			margin: 0 3px;
			border: none;
			text-align: center;
			cursor: pointer;
		}
	}
}

.rdw-editor-main {
	border: 1px solid $gray-300;
	height: 239px;
}

.dz-message {
	text-align: center;
	padding: 100px;
}

.react-datepicker-wrapper {
	width: 100% !important;
}

.ReactModal__Overlay {
	z-index: 1001 !important;
}

.rangeslider__fill {
	background-color: $primary !important;
}

.react-bootstrap-table {
	padding-top: 0px !important;
}

.square-switch {
	.react-switch-bg {
		border-radius: 5px !important;
	}
}

input[type="range"] {
	-webkit-appearance: auto !important;
}

.chat-conversation-height {
	min-height: 500px;
}

.expand-cell {
	cursor: pointer !important;
}

.align-right {
	display: flex;
	justify-content: flex-end;
}

.mr-10 {
	margin-right: 10px !important;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.btn-close-input {
	background-color: transparent;
	border-color: transparent;
}

.container-center-input {
	display: flex;
	align-items: center;
}

.container-page {
	min-height: 800px;
}

.img-crawler {
	max-height: 10rem;
}

.container-img-crawler {
	display: flex;
	flex-direction: row;
}

.MuiAutocomplete-inputRoot {
	padding: 0 !important;
}

.MuiAutocomplete-input {
	background-color: #fff !important;
}

.container-space-between {
	display: flex;
	justify-content: space-between;
}

.MuiInput-underline:before {
	border-bottom: 0 !important;
}

.MuiTextField-root {
	width: 100%;
}

.MuiOutlinedInput-root {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.94rem + 2px);
}

.MuiInputBase-input {
	font-size: 0.9rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
	color: #505d69 !important;
	padding: 0.35rem 0.75rem !important;
}

.MuiAutocomplete-input {
	padding: 0.75rem 0.75rem !important;
}

.pointer-events {
	cursor: pointer;
}

.flex-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.MuiFormControl-marginNormal {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	width: 100%;
}

.MuiOutlinedInput-multiline {
	padding: 0 !important;
}

body .p-component {
	font-size: 0.8rem !important;
}

@media (max-width: 375px) {
	.btn-index-action {
		width: 100%;
		margin-bottom: 5px;
	}

	.wm-100 {
		width: 100%;
	}
}

.approval-icon-focus {
	color: rgb(142, 36, 170);
}

.approval-text-focus {
	color: #2196f3;
}

a.link:visited {
	color: #551a8b;
}

.mr-20 {
	margin-right: 20px;
}

.disabled {
	color: #505d69;
}

.disabled:hover {
	color: #505d69;
}

.mt-60 {
	margin-top: 60px !important;
}

.mr-80 {
	margin-right: 80px;
}

.mt-10 {
	margin-top: 10px;
}

.bg-success {
	background-color: #1cbb8c !important;
}

// -------------------- Início Primefaces Datatable -------------------------

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-page.p-highlight {
	background-color: #5664d2 !important;
	color: white !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	background-color: #5664d2 !important;
	border-radius: 0.25rem !important;
	border: none !important;
}

#overflowAuto .p-datatable-wrapper {
	overflow: auto;
}

.p-datatable .p-datatable-thead .textAlignEnd {
	text-align: end !important;
}

.p-datatable .p-datatable-thead > tr > th {
	text-align: left !important;
	background-color: #fff !important;
}

.p-datatable .p-datatable-thead > tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead > tr > th {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: 60px;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

.p-highlight {
	color: #5664d2 !important;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: #000 !important;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
	background-color: #e6e6e6 !important;
}

// -------------------- Fim Primefaces Datatable -------------------------
li > .conversation-list > .ctext-wrap > .ctext-wrap-content > a {
	color: white;
}

li.right > .conversation-list > .ctext-wrap > .ctext-wrap-content > a {
	color: #5664d2;
}

.chat-conversation .conversation-list .chat-time {
	text-align: left;
}

.chat-conversation .right .conversation-list .chat-time {
	text-align: right;
}

.chat-item {
	background-color: #fff !important;
}

.chat-item:hover {
	background-color: #f1f5f7 !important;
}

.btn-primary.disabled:hover {
	cursor: default !important;
	color: #fff !important;
}

.btn-secondary.disabled:hover {
	cursor: default !important;
}

.dashboard-widget-box {
	height: 100%;
	border-bottom: 3px solid rgba(20, 125, 191, 0.2);
	border-radius: 4px;
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
}

.dashboard-widget-box-body {
	padding: 25px;
	flex-grow: 1;
}

.dashboard-widget-box-content {
	padding-bottom: 10px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.dashboard-widget-box-title {
	font-weight: 400;
	font-size: 16px;
	margin-top: 2px;
	color: #74788d;
}

.dashboard-widget-box-value {
	font-weight: 600;
	font-size: 24px;
}
