.pagamentosIndex {
	.saldo {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 19.512px;
		line-height: 24px;
		color: #505d69;
		margin-bottom: 0;

		b {
			font-family: "Inter";
			font-style: normal;
			font-weight: 600;
			font-size: 26.016px;
			line-height: 31px;
			color: #343a40;
			margin-left: 12px;
		}
	}

	.p-datatable {
		.p-datatable-thead {
			display: none;
		}

		.p-datatable-tbody {
			.p-datatable-row {
				td {
					border: none !important;
					background-color: #fff;
					padding-bottom: 36px !important;

					.container-titulo {
						display: flex;

						.titulo {
							margin: 0 0 0 12px;

							p {
								font-family: "Inter";
								font-style: normal;
								font-weight: 600;
								font-size: 19.512px;
								line-height: 24px;
								color: #343a40;

								&:first-of-type {
									margin-bottom: 6px;
								}

								&:last-of-type {
									margin-bottom: 18px;
								}
							}
						}
					}

					.codes {
						&:last-of-type {
							margin-top: 18px;
						}

						p {
							font-family: "Inter";
							font-style: normal;
							font-weight: 400;
							font-size: 13px;
							line-height: 16px;
							color: #505d69;
							margin-bottom: 0;

							&:first-of-type {
								margin-bottom: 6px;
							}

							b {
								font-weight: 600;
								color: #343a40;
							}
						}
					}

					.arrow-container {
						width: 36px;
						height: 36px;
						background: #1cbb8c;
						border-radius: 4px;
						display: flex;
						justify-content: center;
						align-items: center;

						i {
							font-size: 20px;
							color: #f1f5f7;
						}
					}
				}
			}
		}

		.p-paginator {
			border: none !important;
		}

		.p-column-title {
			display: none !important;
		}
	}

	@media screen and (max-width: 992px) {
		.form-group {
			flex-direction: column;
			width: 100%;
			max-width: none !important;
		}

		.container-center-input {
			padding-left: 0px !important;
			width: auto !important;

			select {
				width: 100%;
			}
		}
	}
}

.custom-container {
	.custom-title {
		font-family: "Inter", sans-serif !important;
		line-height: 20px !important;
		font-weight: 600 !important;
		color: #505d69 !important;
		font-size: 16px !important;
		text-align: left !important;
		border-bottom: 1px solid #eeeeee;
		padding: 20px;
		border-radius: 12px 12px 0 0;
	}

	.custom-confirm {
		background: #f1f5f7 !important;
		border: 1px solid #eeeeee !important;
		border-radius: 4px !important;
		color: #505d69 !important;
	}

	.swal2-html-container {
		font-family: "Inter", sans-serif !important;
		font-weight: 400 !important;
		font-size: 13px !important;
		line-height: 16px !important;
		color: #505d69 !important;
		text-align: left !important;
	}

	.swal2-actions {
		border-top: 1px solid #eeeeee !important;
		justify-content: end !important;
		padding: 20px 20px 0 !important;
	}
}

.sec-shipment {
	width: 80%;
	margin: auto;

	.sec-shipment-subtitle-value {
		color: var(--Roxo-500, #5664d2);

		/* H4 */
		font-family: Inter;
		font-size: 19.512px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.sec-shipment-subtitle-label {
		color: var(--Cinza-900, #252b3b);

		/* H6 */
		font-family: Inter;
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

.warningBox_1 {
	border-radius: 8px;
	border: 1px solid var(--Cinza-100, #eee);
	background: var(--Amarelo-100, #fef2d9);

	/* Sombra padrão */
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
	display: flex;
	height: 64px;
	padding: 16px 24px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 16px;
	align-self: stretch;
	margin-top: 24px;

	p {
		color: var(--Cinza-800, #343a40);

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 0px !important;
	}

	label {
		color: var(--Amarelo-900, #efa200);
		margin-bottom: 0px !important;

		/* MINI */
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

.buttonRemove {
	display: flex;
	height: 38px;
	width: 117px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	background: var(--vermelho-500, #ff3d60);
	border: none;

	color: var(--cinza-00, #fff);
	text-align: center;
	font-family: "Inter", sans-serif;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.gratification-counter {
	display: flex;
	height: 71px;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
	border: 1px solid var(--Cinza-300, #dadbdd);
	background: var(--Cinza-50, #f1f5f7);
	width: 253px;
	margin-bottom: 32px;

	p {
		color: var(--Cinza-500, #8590a5);
		text-align: center;

		/* MINI */
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 0px !important;
	}

	.p-green {
		color: var(--Verde-500, #1cbb8c);

		/* H4 */
		font-family: Inter;
		font-size: 19.512px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.p-grey {
		color: var(--Cinza-500, #8590a5);

		/* H6 */
		font-family: Inter;
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}