@import "./negociacao";
@import "./users";
@import "./anuncio";

.billing {
	@extend .negociacao, .anuncio, .userIndex, .userNew, .userShow;

	.containerGeralLayout {
		display: flex;
		gap: 32px;

		.principalLayout {
			width: 100%;
			max-width: 65%;
		}

		.faturasLayout {
			width: 100%;
			max-width: 35%;

			footer {
				display: flex;
				padding-top: 16px;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
				gap: 8px;
				align-self: stretch;

				hr {
					border-top: 1px solid var(--Cinza-300, #eee) !important;
					width: calc(100% + 48px) !important;
					right: 24px !important;
					position: relative;
					margin-bottom: 16px;
				}

				p {
					color: var(--Cinza-900, #252b3b);
					font-family: Inter;
					font-size: 13.008px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin-bottom: 8px;
				}

				b {
					color: var(--Cinza-500, #8590a5);
					font-family: "Inter";
					font-size: 19.512px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				small {
					color: var(--Cinza-500, #8590a5);
					font-family: "Inter";
					font-size: 13.008px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}

			.faturaContainer {
				display: flex;
				align-items: baseline;
				justify-content: space-between;
				padding: 8px 0;
				border-bottom: 1px solid var(--Cinza-300, #dadbdd);

				p {
					color: var(--Cinza-800, #343a40);
					font-family: "Inter";
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin: 0;
				}

				b {
					color: var(--Cinza-800, #343a40);
					font-family: "Inter";
					font-size: 13.008px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				.subtitle {
					color: var(--Cinza-500, #8590a5);
					font-family: "Inter";
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-top: 4px;
				}
			}

			h4 {
				color: var(--Cinza-500, #8590a5);
				font-family: "Inter";
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 24px;
			}
		}

		.footerTotaisReembolso {
			display: flex;
			margin-top: 24px;
			gap: 24px;

			div {
				display: flex;
				flex-direction: column;
				row-gap: 4px;

				&:nth-child(1) {
					p {
						color: #ff3d60;
					}
				}

				&:nth-child(2) {
					p {
						color: #1cbb8c;
					}
				}

				&:last-of-type {
					margin-left: auto;
				}
			}

			h4 {
				color: var(--Cinza-900, #252b3b);
				font-family: "Inter";
				font-size: 13.008px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin: 0;
			}

			p {
				b {
					font-family: "Inter";
					font-size: 13.008px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				font-family: "Inter";
				font-size: 19.512px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin: 0;
			}
		}
	}

	.title-section {
		color: var(--Cinza-700, #495057);
		font-family: "Inter";
		font-size: 16.26px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.link-contrato-box {
		color: var(--Azul-500, #4aa3ff);
		font-family: "Inter";
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.recebedores-termination {
		margin-top: 24px;

		h3 {
			color: var(--Cinza-700, #495057);
			font-family: "Inter";
			font-size: 16.26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			small {
				color: var(--Cinza-500, #8590a5);
				font-family: "Inter";
				font-size: 16.26px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				padding-left: 8px;
			}
		}

		.recebedores-termination-box {
			border: 1px solid var(--Cinza-300, #dadbdd);
			background: var(--Cinza-00, #fff);
			border-radius: 6px;

			.recebedores-line {
				display: flex;
				padding: 16px;
				flex-direction: row;
				align-items: center;
				gap: 24px;
				align-self: stretch;

				p {
					margin-bottom: 0;
					color: var(--Cinza-600, #505d69);
					font-family: "Inter";
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				b {
					margin-bottom: 0;
					color: var(--Cinza-800, #343a40);
					font-family: "Inter";
					font-size: 13.008px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}
		}
	}
}

.reembolso {
	.response {
		color: var(--Cinza-800, #343a40);
		font-family: "Inter";
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 24px;
	}

	.container-fotos {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.anexo {
			display: flex;
			padding: 0 24px;
			align-items: flex-start;
			gap: 24px;
			align-self: stretch;
			cursor: pointer;
			height: 64px;
			width: 100%;
			border-radius: 4px;
			background: var(--Cinza-50, #f1f5f7);
			align-items: center;

			color: var(--Azul-500, #4aa3ff);
			font-family: "Inter";
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.formulario {
		label {
			color: var(--Cinza-800, #343a40) !important;
			font-family: "Inter" !important;
			font-size: 13px !important;
			font-style: normal !important;
			font-weight: 400 !important;
			line-height: normal !important;
		}

		.label-choice-reembolso {
			color: #1cbb8c !important;
		}

		.label-choice-acrescimo {
			color: #ff3d60 !important;
		}

		.label-bold {
			color: var(--Cinza-700, #495057) !important;
			font-family: "Lato" !important;
			font-size: 14px !important;
			font-style: normal !important;
			font-weight: 700 !important;
			line-height: 20px !important;
			letter-spacing: 0.3px !important;
		}
	}
}

.repaymentBoxInfos {
	padding: 24px !important;

	h5 {
		margin-bottom: 0px !important;
		margin-right: 8px !important;
	}

	.pending-card {
		display: flex;
		padding: 4px 12px;
		align-items: flex-start;
		border-radius: 2px;
		background: var(--Amarelo-500, #fcb92c);
		color: var(--Cinza-00, #fff);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		height: 24px;
	}

	.approved-card {
		display: flex;
		padding: 4px 12px;
		align-items: flex-start;
		border-radius: 2px;
		background: var(--Verde-500, #1cbb8c);
		color: var(--Cinza-00, #fff);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		height: 24px;
	}

	.rejected-card {
		display: flex;
		padding: 4px 12px;
		align-items: flex-start;
		border-radius: 2px;
		background: var(--Vermelho-500, #ff3d60);
		color: var(--Cinza-00, #fff);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		height: 24px;
	}

	.automatic-card {
		display: flex;
		padding: 4px 12px;
		align-items: flex-start;
		border-radius: 2px;
		background: var(--Roxo-500, #5664d2);
		color: var(--Cinza-00, #fff);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		height: 24px;
	}

	.bodyAssets {
		display: flex;
		align-items: flex-end;
		gap: 32px;
		align-self: stretch;

		h5 {
			margin-bottom: 0px;
		}

		p {
			margin-bottom: 4px;
			color: var(--Cinza-600, #505d69);

			/* Body */
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		b {
			color: var(--Cinza-800, #343a40);

			/* H6 */
			font-family: Inter;
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}
