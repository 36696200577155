#dashboard {
	.boxContent {
		margin: 24px 0;
		display: flex;
		align-items: center;
	}

	.app-none {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.custom-select {
		background: none;
	}

	.app-none::-ms-expand {
		display: none;
	}

	.card {
		border-radius: 8px;
		border-bottom: 1px solid var(--Cinza-100, #eee);
		height: -webkit-fill-available;
		margin-bottom: 0;
	}

	.count-indicator {
		display: flex;
		height: 16px;
		padding: 0px 4px;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		border-radius: 20px;
		background: var(--Cinza-100, #eee);

		color: var(--Cinza-500, #8590a5);
		text-align: center;

		/* MINI */
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.widget-select {
		.custom-select {
			background: none;
			width: 100%;
			border: 1px solid var(--Cinza-300, #dadbdd);
			padding-left: 0px;
			padding-right: 14px;
			border-radius: 2px;
		}

		fieldset {
			border: none;
		}

		.Mui-focused {
			div {
				background: transparent;
			}
		}

		.MuiSelect-select {
			display: flex;
			align-items: center;
		}
	}

	.dashboardCard {
		width: 24.4%;
		height: 98px;
		padding: 24px;
		border-radius: 4px;
		border: 0px, 0px, 1.5px, 0px;
		gap: 5px;
		background-color: #f1f5f7;
	}

	.dashboardCard small {
		font-family: Inter;
		font-size: 13px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0px;
		text-align: center;
	}

	.dashboardCard small {
		font-family: Inter;
		font-size: 13px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0px;
	}

	.dashboardCard h3 {
		font-family: Inter;
		font-size: 23px;
		font-weight: 600;
		line-height: 28px;
		letter-spacing: 0px;
		margin-bottom: 3px;
	}

	.dashboardCard p {
		font-family: Inter;
		font-size: 13px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0px;
		margin-bottom: 0px !important;
	}

	.seeMore {
		width: 90px;
		height: 24px;
		padding: 8px, 12px, 8px, 12px;
		border-radius: 4px;
		gap: 12px;
		background-color: #e1e3f7;
		color: #5664d2;
		font-family: Inter;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0px;
		text-align: center;
		border: none;
	}

	.title {
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16.26px;
		line-height: 20px;
		color: #252b3b;
		margin: 0;
	}

	.subtitle {
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		color: #505d69;
		margin: 0 0 0 12px !important;
	}

	.boxImage {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			margin-top: 48px;
		}

		p {
			font-family: "Inter", sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 22.764px;
			line-height: 28px;
			color: #8590a5;
			margin-top: 24px;
		}
	}

	.header-geral {
		display: flex;
		justify-content: space-between;
		margin: 16px 10px;

		div {
			display: flex;
			gap: 8px;
		}

		h1 {
			color: var(--Cinza-700, #495057);

			/* H4 */
			font-family: Inter;
			font-size: 19.512px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 0px !important;
		}

		h3 {
			color: var(--Cinza-500, #8590a5);

			/* Body */
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.last-update-box {
			display: flex;
			padding: 4px 12px;
			align-items: flex-start;
			border-radius: 2px;
			background: var(--Cinza-300, #dadbdd);
			width: 207px;
			align-items: center;

			p {
				margin-bottom: 0px !important;
				color: var(--Cinza-600, #505d69);
				text-align: center;

				/* Body */
				font-family: Inter;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.box-header {
		display: flex;
		justify-content: space-between;

		div {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		h3 {
			color: var(--Cinza-700, #495057);

			/* H5 */
			font-family: Inter;
			font-size: 16.26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		.box-info {
			border-radius: 2px;
			background: var(--Cinza-300, #dadbdd);
			display: flex;
			padding: 4px 12px;
			align-items: flex-start;

			color: var(--Cinza-600, #505d69);
			text-align: center;

			/* Body */
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.box-filter {
		margin: 16px 0px;
	}

	.divisor {
		border: none;
		border-bottom: 1px solid var(--Cinza-100, #eee);
		margin: 0;
		padding: 0;

		position: absolute;
		left: -1.25rem;
		width: calc(100% + 2.5rem);
	}

	.card-wrapper {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		gap: 16px;
		align-self: stretch;
		flex-wrap: wrap;
		margin-top: 40px;
	}

	.min-h .card-box {
		min-height: 125px;
	}

	.card-box {
		border-radius: 4px;
		background: var(--Cinza-50, #f1f5f7);
		display: flex;
		width: 48%;
		// max-width: 210px;
		padding: 16px;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;

		h3 {
			color: var(--Cinza-500, #8590a5);

			/* MINI */
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			margin-bottom: 0px !important;
		}

		h2 {
			color: var(--Cinza-600, #505d69);

			/* H2 */
			font-family: Inter;
			font-size: 26.016px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			display: flex;
			align-items: center;

			margin-bottom: 0px !important;
		}

		h2 svg {
			font-size: 20px;
			padding: 0px 12px;
		}

		p {
			color: var(--Cinza-600, #505d69);
			text-align: center;

			/* Body */
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 0px !important;
		}

		.text-green {
			color: var(--Verde-500, #1cbb8c);
		}

		.text-blue {
			color: var(--Azul-500, #4aa3ff);
		}

		.text-red {
			color: var(--Vermelho-500, #ff3d60);
		}

		.card-value {
			display: flex;
			padding: 4px 12px;
			align-items: flex-start;
			border-radius: 2px;
			background: #dadbdd;
		}

		.card-value-red {
			background: var(--Vermelho-500, #ff3d60);
			color: var(--Cinza-00, #fff);
		}

		.card-value-blue {
			background: var(--Azul-500, #4aa3ff);
			color: var(--Cinza-00, #fff);
		}

		.card-value-green {
			background: var(--Verde-500, #1cbb8c);
			color: var(--Cinza-00, #fff);
		}

		.card-value-brown {
			background: var(--Verde-500, #852032);
			color: var(--Cinza-00, #fff);
		}

		.card-value-yellow {
			background: var(--Verde-500, #efa200);
			color: var(--Cinza-00, #fff);
		}
	}

	.chart-pie-wrapper {
		width: 60%;
		display: flex;
		justify-content: center;
	}

	.chart-graphic-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.chart-wrapper {
		display: flex;
		width: 100%;
		border-radius: 4px;
		border: 1px solid var(--Cinza-300, #dadbdd);
		margin-top: 40px;
	}

	.chart-pie-legend {
		display: flex;
		width: 40%;
		padding: 24px 16px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 16px;
		flex: 1 0 0;
		align-self: stretch;
		background: var(--Cinza-50, #f1f5f7);

		.box-pie-legend {
			display: flex;
			align-items: center;
			gap: 6px;

			p {
				margin-bottom: 0px !important;
			}

			.box-pie-legend-text {
				color: var(--Cinza-600, #505d69);

				/* Body */
				font-family: Inter;
				font-size: 11px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				flex: 1 0 0;
			}

			.box-pie-legend p {
				font-size: 12px;
			}

			.bg-green {
				display: flex;
				justify-content: center;
				padding: 4px 12px;
				align-items: center;
				border-radius: 2px;
				background: var(--Verde-500, #1cbb8c);
				color: var(--Cinza-00, #fff);
				min-width: 50px;
			}

			.bg-yellow {
				display: flex;
				justify-content: center;
				padding: 4px 12px;
				align-items: center;
				border-radius: 2px;
				background: var(--Verde-500, #fcb92c);
				color: var(--Cinza-00, #fff);
				min-width: 50px;
			}

			.bg-blue {
				display: flex;
				justify-content: center;
				padding: 4px 12px;
				align-items: center;
				border-radius: 2px;
				background: var(--Verde-500, #4aa3ff);
				color: var(--Cinza-00, #fff);
				min-width: 50px;
			}

			.bg-gray {
				display: flex;
				justify-content: center;
				padding: 4px 12px;
				align-items: center;
				border-radius: 2px;
				background: var(--Verde-500, #dadbdd);
				color: var(--Cinza-500, #8590a5);
				min-width: 50px;
			}

			.bg-purple {
				display: flex;
				justify-content: center;
				padding: 4px 12px;
				align-items: center;
				border-radius: 2px;
				background: var(--Verde-500, #5664d2);
				color: var(--Cinza-500, #fff);
				min-width: 50px;
			}

			.bg-white {
				display: flex;
				justify-content: center;
				padding: 4px 12px;
				align-items: center;
				border-radius: 2px;
				background: var(--Verde-500, #fff);
				color: var(--Cinza-500, #505d69);
				min-width: 50px;
			}
		}
	}

	.graphic {
		flex-direction: column;

		.chart-graphic-legend {
			display: flex;
			width: 100%;
			padding: 16px;
			align-items: flex-start;
			align-content: flex-start;
			gap: 12px;
			align-self: stretch;
			flex-wrap: wrap;
			background: var(--Cinza-50, #f1f5f7);

			.box-pie-legend {
				display: flex;
				align-items: center;
				gap: 8px;

				p {
					margin-bottom: 0px !important;
				}

				.box-pie-legend-text {
					color: var(--Cinza-600, #505d69);

					/* Body */
					font-family: Inter;
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					flex: 1 0 0;

					min-width: 167px;
				}

				.bg-green {
					display: flex;
					justify-content: center;
					padding: 4px 12px;
					align-items: center;
					border-radius: 2px;
					background: var(--Verde-500, #1cbb8c);
					color: var(--Cinza-00, #fff);
					min-width: 50px;
				}

				.bg-yellow {
					display: flex;
					justify-content: center;
					padding: 4px 12px;
					align-items: center;
					border-radius: 2px;
					background: var(--Verde-500, #fcb92c);
					color: var(--Cinza-00, #fff);
					min-width: 50px;
				}

				.bg-blue {
					display: flex;
					justify-content: center;
					padding: 4px 12px;
					align-items: center;
					border-radius: 2px;
					background: var(--Verde-500, #4aa3ff);
					color: var(--Cinza-00, #fff);
					min-width: 50px;
				}

				.bg-red {
					display: flex;
					justify-content: center;
					padding: 4px 12px;
					align-items: center;
					border-radius: 2px;
					background: var(--Verde-500, #ff3d60);
					color: var(--Cinza-00, #fff);
					min-width: 50px;
				}

				.bg-gray {
					display: flex;
					justify-content: center;
					padding: 4px 12px;
					align-items: center;
					border-radius: 2px;
					background: var(--Verde-500, #dadbdd);
					color: var(--Cinza-500, #8590a5);
					min-width: 50px;
				}

				.bg-purple {
					display: flex;
					justify-content: center;
					padding: 4px 12px;
					align-items: center;
					border-radius: 2px;
					background: var(--Verde-500, #5664d2);
					color: var(--Cinza-500, #fff);
					min-width: 50px;
				}

				.bg-white {
					display: flex;
					justify-content: center;
					padding: 4px 12px;
					align-items: center;
					border-radius: 2px;
					background: var(--Verde-500, #fff);
					color: var(--Cinza-500, #505d69);
					min-width: 50px;
				}
			}
		}
	}

	.footer-1 {
		display: flex;
		padding-top: 16px;
		padding-left: 24px;
		padding-right: 24px;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		align-self: stretch;

		.see-rel {
			display: flex;
			height: 38px;
			padding: 8px 12px;
			justify-content: center;
			align-items: center;
			gap: 12px;
			background-color: transparent;

			border-radius: 4px;
			border: 1px solid var(--Roxo-500, #5664d2);

			color: var(--Roxo-500, #5664d2);
			text-align: center;

			/* H6 */
			font-family: Inter;
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

#dashboard-drop {
	.MuiCheckbox-colorSecondary.Mui-checked {
		color: #5664d2 !important;
	}
}

.counter-green {
	display: flex;
	height: 16px;
	padding: 0px 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background: var(--Verde-100, #d6f3ea);
	margin-left: 8px;

	color: var(--Verde-500, #1cbb8c);
	text-align: center;

	/* MINI */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

#custom-table-1 {
	.p-datatable-wrapper {
		overflow: auto;
	}

	.pi-caret-right:before {
		content: "Próximo";
		color: var(--Cinza-600, #505d69);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.p-dropdown-item .p-highlight {
		display: none;
	}

	p {
		margin-bottom: 0px !important;
		font-family: Inter;
		font-size: 13px;
		color: var(--Cinza-600, #505d69);
	}

	.p-paginator .p-paginator-icon {
		display: flex;
		width: 60px;
		height: 24px;
		flex-direction: column;
		justify-content: center;
		position: inherit;
		margin-top: 0px;
		margin-bottom: 0px;
		left: auto;
		top: auto;
		margin-left: 2px;
	}

	.p-paginator-next {
		width: 60px;
		height: 24px;
		margin-top: 5px !important;
		margin-right: 100px !important;
	}

	.p-paginator-prev {
		width: 60px;
		height: 24px;
		margin-top: 5px !important;
	}

	.pi-caret-left:before {
		content: "Anterior";
		color: var(--Cinza-600, #505d69);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.p-paginator-first {
		display: none;
	}

	.p-paginator-last {
		display: none;
	}

	.p-paginator-bottom {
		padding: 20px 20px 29px 20px !important;
	}

	.p-dropdown {
		position: absolute;
		left: 16px;
	}

	.p-paginator-current {
		display: none;
	}
}

.custom-see-all-table {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid var(--Roxo-500, #5664d2);

	color: var(--Roxo-500, #5664d2);
	text-align: center;

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	background-color: transparent;

	position: absolute;
	bottom: 22px;
	right: 16px;
}

.paginator-new-items p {
	color: var(--Cinza-600, #505d69);
	text-align: center;
	margin-bottom: 0px !important;

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	position: absolute;
	bottom: 33px;
	left: 80px;
}

.paginator-new-items {
	position: relative;
}

.custom-menu-item {
	.MuiListSubheader-sticky {
		position: inherit !important;
	}
}

#custom-table-2 {
	.p-datatable-wrapper {
		overflow: auto;
	}

	.pi-caret-right:before {
		content: "Próximo";
		color: var(--Cinza-600, #505d69);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.p-dropdown-item .p-highlight {
		display: none;
	}

	p {
		margin-bottom: 0px !important;
		font-family: Inter;
		font-size: 13px;
		color: var(--Cinza-600, #505d69);
	}

	.p-paginator .p-paginator-icon {
		display: flex;
		width: 60px;
		height: 24px;
		flex-direction: column;
		justify-content: center;
		position: inherit;
		margin-top: 0px;
		margin-bottom: 0px;
		left: auto;
		top: auto;
		margin-left: 2px;
	}

	.p-paginator-next {
		width: 60px;
		height: 24px;
		margin-top: 5px !important;
		margin-right: 7px !important;
	}

	.p-paginator-prev {
		width: 60px;
		height: 24px;
		margin-top: 5px !important;
	}

	.pi-caret-left:before {
		content: "Anterior";
		color: var(--Cinza-600, #505d69);
		text-align: center;

		/* Body */
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.p-paginator-first {
		display: none;
	}

	.p-paginator-last {
		display: none;
	}

	.p-paginator-bottom {
		padding: 20px 20px 29px 20px !important;
	}

	.p-dropdown {
		position: absolute;
		left: 16px;
	}

	.p-paginator-current {
		display: none;
	}
}
