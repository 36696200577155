.rescisao {
	.assetPersonComissao {
		display: flex;

		h6 {
			color: var(--cinza-800, #343a40);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin: 0;
		}

		h5 {
			color: var(--cinza-600, #505d69);
			font-family: "Inter";
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-top: 6px;
		}

		b {
			color: var(--azul-500, #339af0);
			font-family: "Lato";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0.3px;
		}

		.containerFigure {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			flex: none;
			width: 32px;
			height: 32px;
			background: #f8f9fa;
			margin-right: 16px;
			border-radius: 100px;

			svg {
				color: var(--roxo-500, #5664d2);
				text-align: center;
				font-size: 24px;
				font-style: normal;
				font-weight: 900;
				line-height: normal;
			}
		}
	}

	.info {
		color: var(--cinza-700, #495057);
		font-family: "Inter";
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 0;
		margin-top: 6px;
	}

	.label {
		color: var(--cinza-800, #343a40);
		font-family: "Inter";
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 0 0 5px 0;
	}

	.titleBoxSimulacao {
		margin-bottom: 12px;

		h2 {
			color: var(--roxo-500, #5664d2);
			font-family: "Inter";
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		p {
			color: var(--cinza-800, #343a40);
			text-align: right;
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.containerAssets {
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		margin: 16px 0 24px 0;
	}

	.abandono {
		.containerAbandono {
			display: flex;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 16px;
			row-gap: 8px;
			flex-wrap: wrap;
		}

		.assetAbandono {
			display: flex;
			width: 176px;
			padding: 8px 16px;
			align-items: center;
			gap: 16px;
			border-radius: 8px;
			background: var(--roxo-100, #e1e3f7);

			color: var(--roxo-500, #5664d2);
			font-family: "Inter";
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			svg {
				color: var(--roxo-500, #5664d2);
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 14px;
			}
		}
	}

	.title {
		color: var(--cinza-700, #495057);
		font-family: "Lato";
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0.3px;
		margin-bottom: 12px;
	}

	h5 {
		color: var(--cinza-500, #8590a5);
		font-family: "Inter";
		font-size: 16.26px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.warning {
		display: flex;
		padding: 12px;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
		align-self: stretch;
		border-radius: 4px;

		div {
			color: var(--cinza-800, #343a40);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		h5 {
			font-family: "Inter";
			font-size: 16.26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.parcelaContainer {
		margin-top: 24px;

		h2 {
			color: var(--cinza-500, #8590a5);
			font-family: "Inter";
			font-size: 16.26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 12px;
		}

		p {
			color: var(--cinza-600, #868e96);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 12px;
		}

		.parcelaBox {
			margin-bottom: 12px;

			section {
				display: flex;
				justify-content: space-between;
				align-items: center;

				p {
					color: var(--cinza-800, #343a40);
					font-family: "Inter";
					font-size: 13.008px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin: 0;
				}

				small {
					color: var(--cinza-800, #343a40);
					font-family: "Inter";
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}

			p {
				color: var(--azul-500, #4aa3ff);
				font-family: "Inter";
				font-size: 13.008px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-top: 10px;
			}
		}
	}

	.conhecimentoCard {
		h3 {
			color: var(--cinza-700, #495057);
			font-family: "Inter";
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 28px;
			letter-spacing: 0.3px;
		}

		p {
			color: var(--cinza-700, #495057);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}

.ContractShow {
	.rescisaoBox {
		.buttonBox {
			display: flex;
			gap: 24px;

			button {
				display: flex;
				padding: 8px 12px;
				gap: 12px;
				text-align: center;
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				border-radius: 4px;
				align-items: center;

				&:first-of-type {
					background: var(--roxo-100, #e1e3f7);
					color: var(--roxo-500, #5664d2);
				}

				&:last-of-type {
					background: var(--vermelho-100, #ffc9d2);
					color: var(--vermelho-500, #ff3d60);
				}
			}
		}
	}
}

@media screen and (max-width: 1400px) {
	.buttonBox {
		flex-direction: column;

		button {
			justify-content: center;
		}
	}
}

.card-termination {
	width: 100%;
	border-radius: 8px;
	padding: 16px 24px 32px 24px;
}

.termination-breadcrumb {
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.termination-breadcrumb .breadcrumb-item {
	color: var(--Cinza-800, #343a40);

	/* Body */
	font-family: Inter;
	font-size: 13px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: normal;
}

.termination-right-icon {
	width: 32px;
	height: 32px;
	border-radius: 100px;
	background: var(--Roxo-100, #e1e3f7);
	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 16px;
}

.termination-right-wrapper {
	display: flex;
	align-items: center;
}

.termination-right-wrapper p {
	margin-bottom: 0px;
}

.termination-right-icon svg {
	color: var(--Roxo-500, #5664d2);
	text-align: center;
	font-family: "Font Awesome 6 Pro";
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
}

.termination-right-card {
	display: flex;
	padding: 16px 24px 16px 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	// border-bottom: 1px solid var(--Cinza-100, #eee);
}

.termination-divisor {
	background-color: #eee;
	border: none;
	height: 1px;
	width: calc(100% + 50px);
	margin-left: -26px;
	margin-bottom: 0px;
	margin-top: 5px;
}

.termination-breadcrumb .page-title-box {
	padding: 0px;
}

.overdue-billing-box {
	background-color: #f1f5f7;
	width: 100%;
	height: 92px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
	margin-bottom: 10px;
	justify-content: space-between;
}

.file-label {
	display: flex;
	height: 38px;
	padding: 7px 14px;
	align-items: center;
	gap: 12px;
	align-self: stretch;
	border-radius: 2px;
	border: 1px solid var(--Cinza-300, #dadbdd);
	background: var(--Cinza-00, #fff);
}

.file-label .input-text {
	color: var(--Cinza-500, #8590a5);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.file-label .file-text {
	color: var(--Azul-500, #4aa3ff);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.p-termination-1 {
	color: var(--Cinza-500, #8590a5);

	/* MINI */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.p-termination-input-label {
	color: var(--Cinza-800, #343a40);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.p-termination-fixed {
	color: var(--Cinza-500, #8590a5);

	/* H5 */
	font-family: Inter;
	font-size: 16.26px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px !important;
}

.p-termination-optional {
	color: var(--Cinza-600, #868e96);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	align-self: stretch;
}

.p-termination-optional-title {
	color: var(--Cinza-500, #8590a5);

	/* MINI */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.p-termination-input-value {
	color: var(--Cinza-600, #505d69);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.termination-danger {
	color: var(--Vermelho-500, #ff3d60);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.termination-success {
	display: flex;
	padding: 16px 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 8px 8px 0px 0px;
	background: var(--Verde-100, #d6f3ea);

	/* Sombra padrão */
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

.plus-divisor {
	color: var(--Cinza-500, #8590a5);
	text-align: center;
	font-family: "Font Awesome 6 Pro";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.no-prev-box {
	display: flex;
	padding: 42px 24px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 4px;
	background: var(--Cinza-50, #f1f5f7);
}

.close-bill {
	color: var(--Cinza-800, #343a40);
	text-align: center;
	font-family: "Font Awesome 6 Pro";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.card-termination .warning li {
	color: var(--Cinza-800, #343a40);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.evidences-danger {
	display: flex;
	height: 152px;
	padding: 16px 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-radius: 4px;
	background: var(--Vermelho-200, #ffd7e1);
	margin-top: 14px;
}

.evidences-danger p {
	color: var(--Cinza-900, #252b3b);
	text-align: center;

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 285px;
	margin-bottom: 0px;
}

.mellro-installment {
	color: var(--Azul-500, #4aa3ff);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.landlord-installment {
	color: var(--Amarelo-900, #efa200);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.evidences-danger p:first-child {
	color: var(--Vermelho-500, #ff3d60);
	text-align: center;

	/* H3 */
	font-family: Inter;
	font-size: 22.764px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 4px;
}

.p-type-blue {
	color: var(--Azul-500, #4aa3ff);

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px !important;
}

.p-type-3 {
	color: var(--Cinza-500, #8590a5);
	text-align: center;

	/* H3 */
	font-family: Inter;
	font-size: 22.764px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px;
}

.p-type-green {
	color: var(--Verde-500, #1cbb8c);

	/* H4 */
	font-family: Inter;
	font-size: 19.512px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.wrapper-100 {
	width: 100%;
	display: flex;
	justify-content: center;
}

.p-termination-total {
	color: var(--Vermelho-500, #ff3d60);

	/* H4 */
	font-family: Inter;
	font-size: 19.512px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	margin-bottom: 0px !important;
}

.p-termination-payer {
	color: var(--Cinza-600, #505d69);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-bottom: 0px !important;
	margin-left: 10px;
}

.p-termination-total-label {
	color: var(--Cinza-900, #252b3b);

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 3px;
}

.go-back-1 {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid var(--Roxo-500, #5664d2);
	background-color: transparent;
	color: var(--Roxo-500, #5664d2);
	text-align: center;

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.go-back-1:hover {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid var(--Roxo-500, #5664d2);
	background-color: var(--Roxo-500, #5664d2);
	color: #fff;
	text-align: center;

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	transition: all 1s;
}

.continue-btn-1 {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	background: var(--Roxo-500, #5664d2);
	color: var(--Cinza-00, #fff);
	text-align: center;

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.cardWarningTypeExtrajudicial {
	margin-top: 24px;
	border-radius: 8px;
	border: 1px solid var(--Cinza-100, #eee);
	background: var(--Amarelo-100, #fef2d9);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

	display: flex;
	padding: 16px 24px 32px 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;

	h3 {
		color: var(--Amarelo-900, #efa200);
		font-family: "Inter";
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin: 0;
	}

	p {
		color: var(--Cinza-800, #343a40);
		font-family: "Inter";
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 0;
	}
}

.cardWarningTypeTermination {
	@extend .cardWarningTypeExtrajudicial;

	background: var(--Azul-100, #deeeff);

	h3 {
		color: var(--Azul-500, #4aa3ff);
	}
}

.cardWarningTypeClosure {
	@extend .cardWarningTypeExtrajudicial;

	background: var(--Verde-100, #d6f3ea);

	h3 {
		color: var(--Verde-500, #1cbb8c);
	}
}
