.userIndex {
	.header {
		margin: 24px 0;
		display: flex;
		justify-content: space-between;

		h1 {
			color: var(--cinza-700, #495057);
			font-family: "Inter";
			font-size: 19.512px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.p-datatable {
		border-radius: 4px !important;
		// overflow: hidden;

		.p-datatable-tbody>tr>td {
			border: 1px solid var(--Cinza-300, #dadbdd) !important;
		}

		.p-datatable-thead>tr>th {
			border: 1px solid var(--Cinza-300, #dadbdd) !important;
		}

		.p-datatable-tbody>tr:nth-child(even) {
			background: var(--Cinza-50, #f1f5f7) !important;
		}

		table {
			border-color: #dadbdd;
			border-radius: 4px !important;

			thead {
				color: var(--Cinza-600, #505d69) !important;
				font-family: "Inter" !important;
				font-size: 12px !important;
				font-style: normal !important;
				font-weight: 600 !important;
				line-height: normal !important;
			}

			tbody {
				color: var(--Cinza-600, #505d69);
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.card-general {
		padding: 24px 24px 32px 24px;
		border-radius: 8px;
		border: 1px solid var(--cinza-100, #e9ecef);
		background: var(--cinza-00, #fff);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
		// overflow: hidden;
	}

	.nav-tabs .nav-link {
		margin-bottom: 24px;
	}

	.nav-tabs>li>a,
	.nav-pills>li>a {
		color: var(--cinza-500, #8590a5);
		text-align: center;
		font-family: "Inter";
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.datatable-box {
		display: block;
		width: 100%;
		-webkit-overflow-scrolling: touch;

		.negotiation-active {
			color: var(--azul-500, #4aa3ff);
			text-align: right;
			font-family: "Lato";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 100%;
			gap: 9px;

			svg,
			i {
				color: var(--azul-500, #4aa3ff);
				font-size: 12px;
				font-style: normal;
				font-weight: 900;
				line-height: normal;
			}
		}

		.negotiation-disabled {
			color: var(--Cinza-300, #dadbdd);
			text-align: right;
			font-family: "Lato";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 100%;
			gap: 9px;

			svg,
			i {
				color: var(--Cinza-300, #dadbdd);
				font-size: 12px;
				font-style: normal;
				font-weight: 900;
				line-height: normal;
			}
		}

		.showButton {
			height: 57px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			.fa-chevron-right {
				color: var(--roxo-500, #5664d2);
				text-align: center;
				font-size: 12px;
				font-style: normal;
				font-weight: 900;
				line-height: normal;
			}
		}
	}

	.input-with-black-icon-date {
		position: relative;
		width: fit-content;

		input {
			padding: 10px;
			padding-right: 40px;
			border: 1px solid #ccc;
			border-radius: 4px;

			&::-webkit-calendar-picker-indicator {
				opacity: 0;
			}
		}

		&::after {
			content: url("../../../../assets/images/black-date-icon.svg") !important;
			position: absolute;
			top: 54%;
			right: 25px;
			transform: translateY(-50%);
			pointer-events: none;
		}
	}
}

.userNew {
	.form {
		display: flex;
		justify-content: center;

		.header {
			margin: 24px 0;
			display: flex;
			justify-content: space-between;

			h1 {
				color: var(--cinza-700, #495057);
				font-family: "Inter";
				font-size: 19.512px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}

		.box {
			width: 100%;
			margin-bottom: 16px;
			border-radius: 8px;
			border: 1px solid var(--cinza-100, #eee);
			background: var(--cinza-00, #fff);
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
			padding: 16px 24px 32px 24px;

			h5 {
				color: var(--cinza-500, #8590a5);
				font-family: "Inter";
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 32px;
				text-transform: uppercase;
			}
		}

		.formulario {
			display: flex;
			flex-direction: column;
			gap: 32px;
			row-gap: 16px;

			.checkBox-admin {
				.MuiButtonBase-root {
					padding: 0;
					margin-right: 9px;
				}
			}

			.input {
				border-radius: 2px;
				border: 1px solid var(--cinza-300, #dadbdd);
				background: var(--cinza-00, #fff);
			}

			label {
				color: var(--cinza-800, #343a40);
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.box-footer {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
		padding: 16px;
		border-radius: 8px;
		border: 1px solid var(--cinza-100, #eee);
		background: var(--cinza-00, #fff);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
		margin-bottom: 72px;
	}

	.button-secundary {
		display: flex;
		height: 38px;
		padding: 8px 12px;
		justify-content: center;
		align-items: center;
		gap: 12px;
		border-radius: 4px;
		border: 1px solid var(--roxo-500, #5664d2);
		background-color: #fff;

		color: var(--roxo-500, #5664d2);
		text-align: center;
		font-family: "Inter";
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		&:hover {
			background-color: var(--roxo-500, #5664d2);
			color: #fff;
			transition: ease-in 0.5s;
		}
	}

	.button-primary {
		display: flex !important;
		height: 38px;
		padding: 8px 12px;
		justify-content: center;
		align-items: center;
		gap: 12px !important;
		border-radius: 4px !important;
		background: var(--roxo-500, #5664d2) !important;
		border: none !important;

		color: var(--cinza-00, #fff) !important;
		text-align: center;
		font-family: "Inter" !important;
		font-size: 13.008px !important;
		font-style: normal;
		font-weight: 600 !important;
		line-height: normal;
	}

	.nav-tabs .nav-link {
		margin-top: 8px;
		margin-bottom: 24px;
		color: var(--Cinza-500, #8590a5);
		text-align: center;
		font-family: "Inter";
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.nav-link.active {
		color: var(--Roxo-500, #5664d2);
		text-align: center;
		font-family: "Inter";
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	@media screen and (max-width: 991px) {
		.marginInputMobile {
			margin: 0;
			padding: 0;
			margin-top: 16px;
		}

		.marginInputMobileRua {
			padding-right: 0;
		}
	}
}

.userShow {
	.header {
		margin: 24px 0;
		display: flex;
		justify-content: space-between;

		h1 {
			color: var(--cinza-700, #495057);
			font-family: "Inter";
			font-size: 19.512px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.boxUser {
		width: 100%;
		margin-bottom: 16px;
		border-radius: 8px;
		border: 1px solid var(--cinza-100, #eee);
		background: var(--cinza-00, #fff);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
		padding: 16px 24px;

		.headerUser {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid var(--Cinza-100, #eee);
			padding-bottom: 16px;
			flex-wrap: wrap;

			svg {
				font-size: 20px;
			}

			.userPhoto {
				width: 48px;
				height: 48px;
				border-radius: 80%;
				background: black;
				margin-right: 24px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border: 1px solid #ced4da;
			}

			.userPhotoNone {
				width: 48px;
				height: 48px;
				border-radius: 80%;
				margin-right: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #ced4da;
			}

			.tagsAssets {
				flex: 1;

				h3 {
					color: var(--Cinza-800, #343a40);
					font-family: "Inter";
					font-size: 16.26px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin-bottom: 4px;
				}

				.tagGroup {
					display: flex;
					gap: 4px;

					.tag {
						border-radius: 2px;
						background: var(--Verde-500, #1cbb8c);
						display: flex;
						padding: 4px 12px;
						align-items: flex-start;

						color: var(--Cinza-00, #fff);
						text-align: center;
						font-family: "Inter";
						font-size: 13px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}

			.lastAssets {
				display: flex;
				flex-direction: row;
				gap: 24px;

				p {
					color: var(--Cinza-800, #343a40);
					font-family: "Inter";
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}

		.bodyAssets {
			padding: 24px 0;
			display: flex;
			flex-wrap: wrap;
			gap: 32px;
			border-bottom: 1px solid var(--Cinza-100, #eee);

			.tag {
				border-radius: 2px;
				background: var(--Verde-500, #1cbb8c);
				display: flex;
				padding: 4px 12px;
				align-items: flex-start;
				justify-content: center;
				width: fit-content;

				color: var(--Cinza-00, #fff);
				text-align: center;
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			p {
				color: var(--Cinza-600, #505d69);
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin: 0 0 4px 0;
			}

			b {
				color: var(--Cinza-800, #343a40);
				font-family: "Inter";
				font-size: 13.008px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			a {
				color: var(--Azul-500, #4aa3ff);
				font-family: "Inter";
				font-size: 13.008px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				cursor: pointer;
			}
		}

		.envolvidosContainer {
			flex-direction: column;
			gap: 8px;

			.boxInformationContainerEnvolvidos {
				display: flex;
				flex-wrap: wrap;
				gap: 16px;
				row-gap: 16px;
			}

			.envolvidoBox {
				display: flex;
				padding: 16px;
				border: 1px solid #dadbdd;
				border-radius: 4px;
				box-shadow: 0px 2px 4px 0px #00000014;
				align-items: center;
				width: fit-content;
				min-width: 276px;

				.envolvidoText {
					h2 {
						font-family: "Inter";
						font-size: 13px;
						font-weight: 600;
						line-height: 16px;
						letter-spacing: 0px;
						text-align: left;
						margin: 0;
						margin-top: 4px;
					}

					p {
						font-family: "Inter";
						font-size: 13px;
						font-weight: 400;
						line-height: 16px;
						letter-spacing: 0px;
						text-align: left;
						margin: 0px;
						margin-top: 4px;
					}
				}
			}

			.userPhoto {
				width: 48px;
				height: 48px;
				border-radius: 100% !important;
				background: black;
				margin-right: 16px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border: 1px solid #ced4da;
			}

			.boxInformation {
				p {
					font-family: "Inter";
					font-size: 12px;
					font-weight: 600;
					line-height: 15px;
					letter-spacing: 0px;
					text-align: left;
				}
			}
		}

		.footerAssets {
			padding-top: 16px;
			display: flex;
			gap: 8px;
		}
	}

	.box {
		width: 100%;
		margin-bottom: 16px;
		border-radius: 8px;
		border: 1px solid var(--cinza-100, #eee);
		background: var(--cinza-00, #fff);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
		padding: 16px 24px 32px 24px;

		h5 {
			color: var(--cinza-500, #8590a5);
			font-family: "Inter";
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 32px;
			text-transform: uppercase;
		}
	}

	.boxInfoUser {
		display: flex;
		width: 100%;
		padding: 16px 24px 32px 24px;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		border-radius: 4px;
		background: var(--Cinza-50, #f1f5f7);

		.headerBoxInfoUser {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.title-tag {
				display: flex;
				gap: 16px;
				align-items: center;
			}

			h4 {
				color: var(--Cinza-500, #8590a5);
				font-family: "Inter";
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin: 0;
			}
		}
	}

	.tab-content {
		h2 {
			margin: 40px 0 16px 0;

			color: var(--Cinza-700, #495057);
			font-family: "Inter";
			font-size: 16.26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.boxInfos-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
		row-gap: 16px;

		.boxInfoBody {
			display: flex;
			row-gap: 16px;
			flex-direction: column;
		}

		.boxInformationRow {
			display: flex;
			gap: 36px;
			align-items: flex-end;
		}

		.detailAdmin {
			color: var(--Cinza-500, #8590a5);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin: 0;
		}

		.tag {
			border-radius: 2px;
			background: var(--Verde-500, #1cbb8c);
			display: flex;
			padding: 4px 12px;
			align-items: flex-start;
			justify-content: center;
			width: fit-content;
			height: fit-content;

			color: var(--Cinza-00, #fff);
			text-align: center;
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		p {
			color: var(--Cinza-600, #505d69);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		b {
			color: var(--Cinza-800, #343a40);
			font-family: "Inter";
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.container-historico {
		display: flex;
		gap: 16px;
		margin-top: 32px;

		section {
			border-radius: 4px;
			background: var(--Cinza-50, #f1f5f7);
			display: flex;
			padding: 16px;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			max-width: 182px;
			flex: 1 0 0;
			justify-content: space-between;

			&:last-of-type {
				max-width: max-content;
			}

			color: var(--Cinza-500, #8590a5);
			text-align: center;
			font-family: "Inter";
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			b {
				color: var(--Verde-500, #1cbb8c);
				font-family: "Inter";
				font-size: 22.764px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
	}

	.datatable-box {
		.p-datatable-header {
			margin: 0 0 16px 0;
			padding: 0;
			height: auto;
		}

		h3 {
			color: var(--Cinza-700, #495057);
			font-family: "Inter";
			font-size: 16.26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.title-session {
		color: var(--Cinza-700, #495057);
		font-family: "Inter";
		font-size: 16.26px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin: 0 !important;
	}
}

.btnSecundary {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid var(--Roxo-500, #5664d2);
	background: transparent;

	color: var(--Roxo-500, #5664d2);
	fill: var(--Roxo-500, #5664d2);
	text-align: center;
	font-family: "Inter";
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	&:hover {
		background-color: var(--roxo-500, #5664d2);
		color: #fff;
		fill: #fff;
		transition: ease-in 0.1s;

		svg path {
			fill: #fff;
		}
	}
}

.btnRed {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid #ff3d60;
	background: transparent;

	color: #ff3d60;
	fill: #ff3d60;
	text-align: center;
	font-family: "Inter";
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	&:hover {
		background-color: #ff3d60;
		color: #fff;
		fill: #fff;
		transition: ease-in 0.1s;
	}
}

.buttonGreen {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	background: var(--Verde-500, #1cbb8c);
	border: 1px solid #1cbb8c;

	color: var(--cinza-00, #fff);
	text-align: center;
	font-family: "Inter";
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	&:disabled {
		background: #71d3b6;
		border: 1px solid #71d3b6;
		fill: #71d3b6;
	}

	&:hover:not(:disabled) {
		background-color: transparent;
		color: #1cbb8c;
		fill: #1cbb8c;
		transition: ease-in 0.1s;
	}
}

.buttonPrimary {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	background: var(--roxo-500, #5664d2);
	border: 1px solid #5664d2;

	color: var(--cinza-00, #fff);
	text-align: center;
	font-family: "Inter";
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	&:disabled {
		background: #6e77bb;
		border: 1px solid #6e77bb;
		fill: #6e77bb;
	}

	&:hover:not(:disabled) {
		background-color: var(--roxo-500, transparent);
		color: #5664d2;
		fill: #5664d2;

		transition: ease-in 0.1s;
	}
}

.btnThird {
	display: flex;
	height: 36px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid var(--Cinza-100, #eee);
	background: var(--Cinza-50, #f1f5f7);

	color: var(--Cinza-600, #505d69);
	text-align: center;

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.inputCustomNew {
	border-radius: 2px;
	border: 1px solid var(--Cinza-300, #dadbdd);
	background: var(--Cinza-00, #fff);
	display: flex;
	align-items: center;
	align-self: stretch;
}

.box-filtros {
	padding: 24px;
	border-radius: 8px;
	border: 1px solid var(--Cinza-100, #e9ecef);
	background: var(--Cinza-00, #fff);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
	margin-bottom: 16px;
	display: flex;
	flex-wrap: wrap;
	row-gap: 16px;

	.filtroUnit {
		display: flex !important;
		padding: 16px !important;
		align-items: center;
		gap: 16px;
		align-self: stretch;
		border-radius: 4px;
		background: var(--Cinza-50, #f1f5f7);
		justify-content: space-between;

		.divisionArrow {
			display: flex;
			align-items: center;
			margin: 0 16px;

			svg {
				color: var(--Cinza-500, #8590a5);
				text-align: center;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				cursor: default;
			}
		}

		svg {
			cursor: pointer;
			color: var(--Cinza-800, #343a40);
			text-align: center;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}

.selectorFilter {
	display: flex;
	justify-content: center;
	width: 300px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	border-radius: 2px;
	border: 1px solid var(--Cinza-300, #dadbdd);
	background: var(--Cinza-00, #fff);
	height: 38px;

	color: var(--Cinza-800, #343a40) !important;
	font-family: "Inter";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	&:hover {
		display: flex;
		align-items: center;
		width: 300px;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		border-radius: 2px;
		border: 1px solid var(--Cinza-300, #dadbdd) !important;
		background: var(--Cinza-00, #fff) !important;
		height: 38px;

		color: var(--Cinza-800, #343a40) !important;
		font-family: "Inter";
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}

.show>.btn-secondary.dropdown-toggle {
	color: #fff;
	border: 1px solid var(--Cinza-300, #dadbdd);
	background: var(--Cinza-00, #fff);
}

.generalSearch {
	width: 100%;
	margin-right: 32px;
	position: relative;
	height: 38px;

	button {
		height: 38px;
	}

	input {
		width: 100%;
		height: 38px;
	}

	svg {
		position: absolute;
		top: 11px;
		right: 14px;
	}
}

.tag {
	border-radius: 2px;
	background: var(--Verde-500, #1cbb8c);
	display: flex;
	padding: 4px 12px;
	align-items: flex-start;
	width: fit-content;

	color: var(--Cinza-00, #fff);
	text-align: center;
	font-family: "Inter";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.spinnerSpin {
	animation: spin infinite 5s linear;
}

.tabName {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.indicadorEdit {
	border-radius: 20px;
	background: var(--Cinza-100, #5664d2);
	display: flex;
	width: 24px;
	height: 16px;
	padding: 0px 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: var(--Cinza-00, #fff);
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.indicadorUsuarios {
	display: flex;
	padding: 0px 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background: var(--Cinza-100, #eee);

	color: var(--Cinza-500, #8590a5);
	text-align: center;
	font-family: "Inter";
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.lastAssetsMobile {
	display: none;
	flex-direction: row;
	gap: 24px;
	flex-wrap: wrap;
	row-gap: 4px;

	p {
		color: var(--Cinza-800, #343a40);
		font-family: "Inter";
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 0;

		&:last-of-type {
			margin-bottom: 4px;
		}
	}
}

.p-datatable-emptymessage td {
	color: var(--Cinza-500, #8590a5);
	font-family: "Inter";
	font-size: 22.764px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.closeTag {
	top: 4px;
	right: 4px;
	border-radius: 2px;
	background: rgba(241, 245, 247, 0.5);
	position: absolute;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	i {
		color: var(--Cinza-800, #343a40);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		line-height: normal;
		margin: 0;
		padding: 0;
	}
}

.p-dropdown-trigger {
	height: 13px !important;
	width: 10px !important;
	top: 42% !important;
	right: 7px !important;
	font-size: 10px !important;
	padding-right: 1.5em;
}

.newAnticipation {
	.custom-select {
		background: none;
	}

	.boxContractAnticipation {
		display: flex;
		padding: 24px;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		align-self: stretch;
		border-radius: 4px;
		background: var(--Cinza-50, #f1f5f7);
		margin: 16px 0px;

		p {
			margin-bottom: 0px !important;

			color: var(--Cinza-800, #343a40);
			text-align: right;

			/* H6 */
			font-family: Inter;
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		label {
			margin-bottom: 0px !important;
			color: var(--Cinza-600, #505d69);

			/* Body */
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.deleteContract {
		display: flex;
		align-items: center;
		cursor: pointer;

		font-size: 20px;
	}

	.values-footer {
		h3 {
			color: var(--Cinza-900, #252b3b);

			/* H6 */
			font-family: Inter;
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 8px !important;
		}

		h1 {
			font-family: Inter;
			font-size: 19.512px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 0px !important;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 992px) {
	.header {
		flex-direction: column;

		li {
			font-family: "Inter";
			font-size: 13px !important;
			font-style: normal;
			font-weight: 400 !important;
			line-height: normal;
		}
	}

	.boxInfos-container {
		grid-template-columns: 1fr !important;
	}

	.boxInformationRow {
		flex-wrap: wrap;
	}

	.lastAssets {
		display: none !important;
	}

	.lastAssetsMobile {
		display: flex;
	}
}