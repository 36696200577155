//
// Fonts
//

// Nunito - Google Font
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap');

// Lato - Google Font
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');


@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../../../fonts/inter-light.woff2?v=3.13") format("woff2"),
		url("../../../fonts/inter-light.woff?v=3.13") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../../../fonts/inter-regular.woff2?v=3.13") format("woff2"),
		url("../../../fonts/inter-regular.woff?v=3.13") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../../../fonts/inter-medium.woff2?v=3.13") format("woff2"),
		url("../../../fonts/inter-medium.woff?v=3.13") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../../../fonts/inter-bold.woff2?v=3.13") format("woff2"),
		url("../../../fonts/inter-bold.woff?v=3.13") format("woff");
}