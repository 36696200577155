.pi-sort-alt.p-sortable-column-icon:before {
	content: "" !important;
	background-image: url('../../../../assets/images/orderIconTable.svg') !important;

	width: 8px;
	height: 12px;
	display: flex;
	background-repeat: no-repeat;
	margin-left: 8px;
}

.anuncio {
	.close-icon {
		color: var(--Cinza-800, #343A40);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding-top: 8.5px;
		cursor: pointer;
	}

	.label-principalRadio {
		color: var(--Cinza-800, #343A40);
		font-family: 'Inter';
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.radioLabel {
		color: var(--Cinza-600, #505D69);
		font-family: 'Inter';
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.userPhoto {
		border-radius: 4px !important;
	}

	.userPhotoNone {
		border-radius: 4px !important;
	}

	.groupAlign {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.inBlue {
		color: var(--Azul-500, #4AA3FF) !important;
		font-family: 'Inter' !important;
		font-size: 13.008px !important;
		font-style: normal !important;
		font-weight: 600 !important;
		line-height: normal !important;
		cursor: pointer !important;
	}

	.lastAssets {
		gap: 10px !important;

		p,
		svg {
			color: var(--Azul-500, #4AA3FF) !important;
			font-family: 'Inter' !important;
			font-size: 13.008px !important;
			font-weight: 600 !important;
		}
	}

	.boxInfos-container-unico {
		grid-template-columns: 1fr;
		margin-top: 16px;

		.content-box {
			h5 {
				margin-bottom: 8px;
			}

			.tag {
				margin-top: 4px;
			}
		}

		.boxInfoBody {
			width: 100%;
		}
	}

	.comissioningRow {
		align-items: center !important;
		display: grid !important;
		width: 172px;
		margin-right: 24px;

		a {
			margin-bottom: 0;
			color: var(--Azul-500, #4AA3FF);
			font-family: 'Lato';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			cursor: pointer;
		}
	}

	.comissionamento-body {
		row-gap: 24px !important;
		width: 100%;
	}

	.commissionedBox {
		display: flex;
		width: 100%;
		border-bottom: 1px solid var(--Cinza-100, #DADBDD);
		padding: 8px 0 16px 0;
	}

	.commissioningInfos {
		display: flex;

		p {
			font-size: 13px;
			color: #505D69;
			line-height: 16px;
			margin-bottom: 4px;
		}
	}

	.comissionamento-nao-configurado {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			color: var(--Cinza-500, #8590A5);
			text-align: center;
			font-family: 'Inter';
			font-size: 22.764px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 0;
		}
	}

	.text-comissioning {
		display: flex;
		flex-direction: column;
		gap: 8px;

		p {
			color: var(--Cinza-800, #343A40);
			font-family: 'Inter';
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 0;
			margin-top: 2px;
		}

		b {
			color: var(--Cinza-800, #343A40);
			font-family: 'Inter';
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.noPhotoAnnouncement {
		background-image: url('../../../../assets/images/noFoundAnnouncement.png') !important;
	}

	.no-images-box {
		display: flex;
		justify-content: center;
		align-items: center;

		color: var(--Cinza-500, #8590A5);
		text-align: center;
		font-family: 'Inter';
		font-size: 22.764px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.formularioDetalhes {
		row-gap: 32px !important;

		.titleLabel {
			color: var(--Cinza-500, #8590A5) !important;
			font-family: 'Inter';
			font-size: 12px !important;
			font-style: normal;
			font-weight: 600 !important;
			line-height: normal;
			padding-left: 9px;
		}

		.labelBody {
			color: var(--Cinza-600, #505D69) !important;
			font-family: 'Inter' !important;
			font-size: 13px !important;
			font-style: normal !important;
			font-weight: 400 !important;
			line-height: normal !important;
		}
	}
}