.afiliado {
	.header-link {
		display: flex;
		gap: 24px;
		margin: 32px 0;
		align-items: baseline;

		h6 {
			margin: 0;
			color: var(--cinza-600, #505D69);
			font-family: 'Inter';
			font-size: 26.016px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		small {
			margin: 0;
			color: var(--cinza-500, #8590A5);
			font-family: 'Inter';
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		p {
			margin: 0;
			color: var(--cinza-600, #505D69);
			font-family: 'Inter';
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.btn-remove {
		display: flex;
		height: 36px;
		width: 80px;
		padding: 8px 12px;
		justify-content: center;
		align-items: center;
		gap: 12px;
		border-radius: 4px;
		background: var(--vermelho-500, #FF3D60);

		color: var(--cinza-00, #FFF);
		text-align: center;
		font-family: 'Inter', sans-serif;
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	hr {
		margin: 32px 0;
	}

	label {
		color: var(--cinza-800, #343A40);
		font-family: 'Inter';
		font-size: 13.008px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.details-link {
		color: var(--cinza-600, #505D69);
		font-family: 'Inter';
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 0;
		width: 254px;
	}

	.caixaAfiliados {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 60px;
		flex: 1 0 0;
		padding: 24px;
		align-items: flex-start;
		margin-top: 32px;

		border-radius: 8px;
		background: var(--cinza-50, #F1F5F7);

		section {
			flex: 1;
		}

		.erro-porcentagem-afiliado {
			color: var(--vermelho-500, #FF3D60);
			font-family: 'Inter';
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin: 10px 0;
		}
	}

	.title-session {
		margin: 32px 0 16px 0 !important;
		color: var(--Cinza-700, #495057);
		font-family: 'Inter';
		font-size: 16.26px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.container-historico {
		display: flex;
		gap: 16px;

		section {
			border-radius: 4px;
			background: var(--Cinza-50, #F1F5F7);
			display: flex;
			padding: 16px;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			max-width: 182px;
			flex: 1 0 0;
			justify-content: space-between;

			&:last-of-type {
				max-width: max-content;
			}

			color: var(--Cinza-500, #8590A5);
			text-align: center;
			font-family: 'Inter';
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			b {
				color: var(--Verde-500, #1CBB8C);
				font-family: 'Inter';
				font-size: 22.764px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
	}

	.adicionar-indicado-box {
		border-radius: 4px;
		border: 1px solid var(--Cinza-300, #DADBDD);
		background: var(--Cinza-00, #FFF);

		display: flex;
		padding: 16px;
		align-items: center;
		gap: 24px;
		align-self: stretch;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	.notAfiliado {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 32px;
		margin-bottom: 0px;

		color: var(--Cinza-600, #505D69);
		font-family: 'Inter';
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}