.card-reembolso {
	padding: 24px;
	width: 456px;
	background: #f1f5f7;
	border-radius: 4px;
	margin-bottom: 12px;

	div {
		display: flex;

		h3 {
			font-family: "Inter", sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 16.26px;
			line-height: 20px;
		}

		h4 {
			font-family: "Inter";
			font-style: normal;
			font-weight: 600;
			font-size: 16.26px;
			line-height: 20px;
			color: #8590a5;
			margin-left: 10px;
		}
	}

	p {
		margin-top: 12px;
		margin-bottom: 12px;
		padding: 0;
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		color: #505d69;
	}

	button {
		width: 80px;
		height: 36px;
		background: #5664d2;
		border-radius: 4px;

		font-family: "Inter", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 13.008px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #e1e3f7;

		&:hover {
			background: #394390;
			color: #d5d6e1;
		}

		&:active {
			background: #232a5d;
		}
	}
}

.btn-blue {
	width: 62px;
	height: 36px;
	background: #5664d2;
	border-radius: 4px;

	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 13.008px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #e1e3f7;

	&:hover {
		background: #394390;
		color: #d5d6e1;
	}

	&:active {
		background: #232a5d;
	}
}

.button-anexar {
	width: 69px;
	height: 36px;
	background: #8590a5;
	border-radius: 4px;
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 13.008px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #e1e3f7;
	margin-bottom: 16px;

	&:hover {
		background: #778091;
		color: #d5d6e1;
	}

	&:active {
		background: #555b67;
	}
}

.label-anexo {
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	text-decoration-line: underline;
	color: #505d69;
	margin-bottom: 20px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.fa-times {
	font-size: 13px;
	line-height: 13px;
	color: #ff3d60;
	padding-left: 10px;
	cursor: pointer;
}

.col-form-label-show {
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #4aa3ff;
	margin-top: 28px;
	margin-bottom: 16px;
}

.doc-juridico-select::-webkit-file-upload-button {
	visibility: hidden;
}

.doc-juridico-select {
	width: 150px;
	min-width: 150px;
}

.doc-juridico-select::before {
	content: "Selecionar arquivo";
	display: inline-block;
	background: #5664d2;
	border-radius: 3px;
	padding: 10px 12px;
	outline: none;
	white-space: nowrap;
	user-select: none;
	-webkit-user-select: none;
	cursor: pointer;
	color: #e1e3f7;
	font-weight: 700;
	font-size: 13px;
	height: 40px;
}

.response-data {
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #505d69;
	margin-bottom: 0;
}

.without-padding div {
	padding: 0px !important;
}

.contractual-edit {
	width: 50%;

	.warning {
		display: flex;
		padding: 12px;
		flex-direction: column;
		align-items: flex-start;
		gap: 12px;
		align-self: stretch;
		border-radius: 4px;

		div {
			color: var(--cinza-800, #343a40);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		h5 {
			font-family: "Inter";
			font-size: 16.26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.box-blue {
		background-color: #e1e3f7;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		height: 48px;
		width: 48px;
		margin-right: 8.5px;
	}

	h1 {
		//styleName: H3;
		font-family: Inter;
		font-size: 23px;
		font-weight: 600;
		line-height: 28px;
		letter-spacing: 0px;
		text-align: left;
	}

	h5 {
		//styleName: H5;
		font-family: Inter;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0px;
		text-align: left;
		color: #505d69;
	}

	h6 {
		//styleName: H6;
		font-family: Inter;
		font-size: 13px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0px;
		text-align: left;
		color: #8590a5;
	}

	p {
		//styleName: Body;
		font-family: Inter;
		font-size: 13px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0px;
		text-align: left;
		color: #8590a5;
		margin-bottom: 5px;
		margin-top: 5px;
	}

	.input-label {
		color: #343a40;
	}

	small {
		//styleName: Body;
		font-family: Inter;
		font-size: 13px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0px;
		text-align: left;

		color: #505d69;
	}

	hr {
		color: #dadbdd;
		width: 100%;
		margin-top: 35px;
		margin-bottom: 35px;
	}
}

.card-friendly-agreement {
	width: 70%;

	.tabelaBillingsAcordo {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-self: stretch;
		overflow: hidden;

		border-radius: 6px;
		border: 1px solid var(--Cinza-300, #DADBDD);

		.billingColumn {
			display: flex;
			padding: 16px;
			align-items: flex-start;
			gap: 16px;
			align-self: stretch;
			min-height: 76px;
			width: 100%;

			background: var(--Cinza-00, #FFF);

			&:nth-child(even) {
				background: var(--Cinza-50, #F1F5F7);
				border-top: 1px solid var(--Cinza-300, #DADBDD);
				border-bottom: 1px solid var(--Cinza-300, #DADBDD);
			}

			&:last-child {
				border-bottom: none;
			}

			div {
				h5 {
					color: var(--Cinza-600, #505D69);
					font-family: 'Inter';
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				p {
					color: var(--Cinza-800, #343A40);
					font-family: 'Inter';
					font-size: 13px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin: 0;
				}

				&:nth-child(1) {
					color: var(--Cinza-800, #343A40);
					font-family: 'Inter';
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					flex: 1 0 0;
					align-self: stretch;
				}
			}
		}
	}
}

.friendly-agreement-breadcrumb {
	width: 70%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.friendly-agreement-breadcrumb .page-title-box {
	padding: 0px;
}

.webhook-error-breadcrumb {
	width: 70%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.webhook-error-breadcrumb .page-title-box {
	padding: 0px;
}

.webhook-error-breadcrumb .breadcrumb-item {
	color: var(--Cinza-800, #363c41);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.overdue-billing-box {
	background-color: #f1f5f7;
	width: 100%;
	height: 92px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
	margin-bottom: 10px;
	justify-content: space-between;
}

.file-label {
	display: flex;
	height: 38px;
	padding: 7px 14px;
	align-items: center;
	gap: 12px;
	align-self: stretch;
	border-radius: 2px;
	border: 1px solid var(--Cinza-300, #dadbdd);
	background: var(--Cinza-00, #fff);
}

.file-label .input-text {
	color: var(--Cinza-500, #8590a5);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.file-label .file-text {
	color: var(--Azul-500, #4aa3ff);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.p-type-1 {
	color: var(--Cinza-600, #505d69);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 5px;
}

.p-type-bold {
	color: var(--Cinza-800, #343a40);

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 5px !important;
}

.p-type-header {
	color: var(--Cinza-500, #8590a5);

	/* MINI */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.p-type-2 {
	color: var(--Cinza-500, #8590a5);

	/* H6 */
	font-family: Inter;
	font-size: 19.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.plus-divisor {
	color: var(--Cinza-500, #8590a5);
	text-align: center;
	font-family: "Font Awesome 6 Pro";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.no-prev-box {
	display: flex;
	padding: 42px 24px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 4px;
	background: var(--Cinza-50, #f1f5f7);
}

.close-bill {
	color: var(--Cinza-800, #343a40);
	text-align: center;
	font-family: "Font Awesome 6 Pro";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.p-type-blue {
	color: var(--Azul-500, #4aa3ff);
}

// Vistoria
.inspectionTable .repair-box {
	display: flex;
	padding: 4px 12px;
	align-items: flex-start;
	justify-content: center;
	border-radius: 2px;
	background: var(--Vermelho-500, #ff3d60);
	color: var(--Cinza-00, #fff);
	text-align: center;

	/* Body */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.inspectionTable .good-state-box {
	display: flex;
	padding: 4px 12px;
	align-items: flex-start;
	justify-content: center;
	border-radius: 2px;
	background: var(--Verde-500, #1cbb8c);
	color: var(--Cinza-00, #fff);
	text-align: center;
	justify-content: center;

	/* Body */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0px !important;
}

.continue-btn-1 {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	background: var(--Roxo-500, #5664d2);
	color: var(--Cinza-00, #fff);
	text-align: center;

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px !important;
}

.p-type-3 {
	color: var(--Cinza-500, #8590a5);
	text-align: center;

	/* H3 */
	font-family: Inter;
	font-size: 22.764px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px;
}

.p-type-green {
	color: var(--Verde-500, #1cbb8c);

	/* H4 */
	font-family: Inter;
	font-size: 19.512px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.wrapper-100 {
	width: 100%;
	display: flex;
	justify-content: center;
}

.go-back-1 {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid var(--Roxo-500, #5664d2);
	background-color: transparent;
	color: var(--Roxo-500, #5664d2);
	text-align: center;

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.go-back-1:hover {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	border: 1px solid var(--Roxo-500, #5664d2);
	background-color: var(--Roxo-500, #5664d2);
	color: #fff;
	text-align: center;

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	transition: all 1s;
}

.continue-btn-1 {
	display: flex;
	height: 38px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	border-radius: 4px;
	background: var(--Roxo-500, #5664d2);
	color: var(--Cinza-00, #fff);
	text-align: center;
}

.inspection-count {
	display: flex;
	width: 24px;
	height: 16px;
	padding: 0px 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background: var(--Cinza-100, #eee);
	margin-bottom: 0px !important;
}

.inspection-table-title {
	color: var(--Cinza-700, #495057);

	/* H5 */
	font-family: Inter;
	font-size: 16.26px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-right: 8px;
	margin-bottom: 0px !important;
}

.copy-inspection {
	background-color: transparent;
	border: none;
	color: var(--Azul-500, #4aa3ff);

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding: 0px;
}

.inspection-header {
	display: flex;
	padding: 16px;
	align-items: center;
	gap: 24px;
	align-self: stretch;
	border-radius: 4px;
	background: var(--Cinza-50, #f1f5f7);
	margin-bottom: 24px;
	justify-content: space-between;
}

.inspection-p {
	color: var(--Cinza-600, #505d69);

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 4px !important;
}

.inspection-status-box {
	color: var(--Cinza-00, #fff);
	text-align: center;

	/* Body */
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 4px 12px;
	border-radius: 2px;
}

.inspection-p-2 {
	color: var(--Cinza-800, #343a40);

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px !important;
}

.inspection-inactive {
	display: flex;
	width: 72px;
	height: 72px;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;

	color: var(--Cinza-100, #eee);
	text-align: center;
	font-family: "Font Awesome 6 Pro";
	font-size: 32px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;

	background-color: #8590a5;
	opacity: 0.4;
}

.media-box {
	max-width: 168px;
	min-width: 80px;
	position: relative;
	display: flex;
	justify-content: flex-end;
}

.media-box img {
	height: 72px;
	width: 72px;
	object-fit: cover;
	position: absolute;
	padding: 4px;
	border-radius: 4px;
	background-color: #fff;
}

.medias-more {
	width: 65px;
	height: 65px;
	margin: 4px;
	flex-shrink: 0;
	border-radius: 2px;
	opacity: 0.75;
	background: var(--Roxo-500, #5664d2);
	position: absolute;
	left: 68.2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.medias-more p {
	color: var(--Cinza-00, #fff);

	/* H4 */
	font-family: Inter;
	font-size: 19.512px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0px !important;
}

.repayments-inspection-box {
	padding: 16px 24px 32px 24px;
}

.repayments-inspection-box h3 {
	color: var(--Cinza-500, #8590a5);

	/* MINI */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding: 8px 0px;
	margin-bottom: 24px;
}

.repayment-inspection-totals {
	display: flex;
	gap: 24px;
	margin-top: 24px;
	justify-content: space-between;
}

.repayment-inspection-totals h2 {
	color: var(--Cinza-900, #252b3b);

	/* H6 */
	font-family: Inter;
	font-size: 13.008px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.repayment-inspection-totals .add {
	color: var(--Vermelho-500, #ff3d60);

	/* H4 */
	font-family: Inter;
	font-size: 19.512px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.repayment-inspection-totals .discount {
	color: var(--Verde-500, #1cbb8c);

	/* H4 */
	font-family: Inter;
	font-size: 19.512px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

#property-inpsection-items .p-datatable-emptymessage {
	background-color: #f1f5f7 !important;
}

.inspection-breadcrumb .page-title-box {
	padding: 0px;
}

.link-underline {
	text-decoration-line: underline !important;
}