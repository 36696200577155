//
// Extras pages.scss
//

// pricing

.pricing-nav-tabs {
    display: inline-block;
    background-color: $card-bg;
    box-shadow: $box-shadow;
    padding: 4px;
    border-radius: 7px;
    li {
        display: inline-block;
    }
}

.pricing-box {
    .plan-features {
        li {
            padding: 7px 0px;
        }
    }
}

/*********************
    Faqs
**********************/

.faq-nav-tabs {
    .nav-item {
        margin: 0px 8px;
    }
    .nav-link {
        text-align: center;
        margin-bottom: 8px;
        border: 2px solid $border-color;
        color: $body-color;
        .nav-icon {
            font-size: 40px;
            margin-bottom: 8px;
            display: block;
        }

        &.active {
            border-color: $primary;
            background-color: transparent;
            color: $body-color;

            .nav-icon {
                color: $primary;
            }
        }
    }
}

.text-error {
    font-size: 120px;
    @media (max-width: 575.98px) {
        font-size: 86px;
    }
}

.error-text {
    color: $danger;
    position: relative;

    .error-img {
        position: absolute;
        width: 120px;
        left: -15px;
        right: 0;
        bottom: 47px;

        @media (max-width: 575.98px) {
            width: 86px;
            left: -12px;
            bottom: 38px;
        }
    }
}

#custom-scroll::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

#custom-scroll::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}

#custom-scroll::-webkit-scrollbar-thumb {
    background-color: #8590a5;
    border-radius: 20px;
    border: 10px solid transparent;
}

// INDICES RENOVACAO
.indices-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}

.indice-sum {
    background-color: #f1f5f7;
    display: flex;
    align-items: center;
    padding: 24px;
    border-radius: 10px;
    width: 49%;
}

.indice-sum p {
    font-size: 26px;
    font-weight: 600;
    margin: 0 !important;
    color: #343a40;
}

.indice-sum span {
    color: #5664d2;
}
