.negociacao {
	.boxInfo-imovel-negociacao {
		.boxInfo-imovel-header {
			display: flex;
			align-items: baseline;
			gap: 16px;
		}

		h4 {
			color: var(--Cinza-500, #8590a5);
			font-family: "Inter";
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 0;
			margin-top: 19.5px;
		}

		.negotiation-subtitle-1 {
			color: var(--Cinza-700, #495057);

			/* H6 */
			font-family: Inter;
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		.box-purple {
			display: flex;
			padding: 4px 12px;
			align-items: flex-start;
			border-radius: 2px;
			background: var(--Roxo-500, #5664d2);

			color: var(--Cinza-00, #fff);
			text-align: center;

			/* Body */
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

			margin-left: 8px;
		}

		#datatable-users-proposition .p-column-title {
			color: #333333 !important;
		}

		#datatable-users-proposition {
			margin-bottom: 20px;
		}

		.container-imovel-imagem {
			display: flex;
			flex: 1;
		}

		.boxInfoUser {
			display: flex;
			width: 100%;
			padding: 16px 24px;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			border-radius: 4px;
			background: var(--Cinza-50, #f1f5f7);
			overflow: hidden;

			.boxUser {
				margin-bottom: 0px;
				border-radius: 8px;
				border: none;
				background: transparent;
				box-shadow: none;
				padding: 0px;
			}

			.headerUser {
				display: flex;
				justify-content: flex-start !important;
				border-bottom: none;
				padding-bottom: 0px;
				flex-wrap: wrap;
				gap: 24px;
				align-items: center;

				.tagsAssets {
					flex: none;
				}
			}

			.headerBoxInfoUser {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				.title-tag {
					display: flex;
					gap: 16px;
					align-items: center;
				}

				h4 {
					color: var(--Cinza-500, #8590a5);
					font-family: "Inter";
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin: 0;
				}
			}
		}
	}

	.send-file {
		label {
			color: var(--Cinza-800, #343a40);
			font-family: "Inter";
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		h4 {
			color: var(--Cinza-500, #8590a5);
			font-family: "Inter";
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 0;
			margin-bottom: 8px;
		}

		.MuiTextField-root:not(.descriptionField) {
			background-color: #fff;
		}

		.MuiInputBase-root {
			background-color: #fff !important;
		}

		.MuiInputBase-inputMultiline {
			min-height: 70px;
		}

		input[name="description"] {
			min-height: 70px;
		}

		input[name="description"]::placeholder {
			align-self: baseline;
			display: flex;
			justify-content: start;
		}

		.descriptionField {
			.MuiOutlinedInput-root {
				height: fit-content;
			}
		}
	}

	.boxUserTableUsers {
		display: flex;
		justify-content: center;

		.userPhoto {
			width: 48px;
			height: 48px;

			border-radius: 3000px !important;
			border: 2px solid var(--Cinza-00, #fff);

			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.userPhotoNone {
			width: 48px;
			height: 48px;

			border-radius: 3000px !important;
			border: 1px solid #ced4da;

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.filtroOcorrencia {
		padding: 16px 0 0 0 !important;
	}

	.analiseReview {
		display: flex;
		flex-direction: column;
		row-gap: 24px;
		gap: 16px;
		padding: 16px 24px 32px 24px;

		header {
			color: var(--Cinza-500, #8590a5);
			font-family: "Inter";
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			display: flex;
			gap: 16px;
		}

		.body {
			display: flex;
			gap: 24px;

			.body-1 {
				display: flex;
				flex-direction: column;
				row-gap: 16px;
				width: 100%;
				max-width: 33%;

				.container-info1 {
					display: flex;
					gap: 24px;
				}
			}

			.body-2 {
				width: 100%;
				max-width: 33%;
			}

			.body-3 {
				width: 100%;
				max-width: 33%;
			}
		}
	}

	.headerUser {
		position: relative;
	}

	.fotoParticipantes {
		display: flex;
		align-items: end;
		bottom: 15px;
		left: 50px;
		position: absolute;

		div {
			width: 48px !important;
			height: 48px !important;
			flex: none;
		}

		.userPhotoNone {
			background-color: #fff;
		}

		div:not(:first) {
			margin-left: -15%;
		}
	}

	.datatableFC {
		.p-datatable table {
			width: 100%;
		}

		.p-datatable thead {
			display: none;
		}

		.p-datatable tbody {
			td {
				padding: 8px 16px;
			}
		}
	}

	.lineBox {
		width: calc(100% + 48px);
		right: 24px;
		position: relative;
	}

	.atualVersionHeader {
		display: flex;
		justify-content: space-between;

		span {
			&:first-of-type {
				color: var(--Verde-500, #1cbb8c);
				font-family: "Inter";
				font-size: 13.008px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			&:last-of-type {
				color: var(--Cinza-600, #505d69);
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

				b {
					color: var(--Cinza-800, #343a40);
					font-weight: 600;
				}
			}
		}
	}

	.footer-values-negotiation {
		justify-content: space-between !important;
		align-items: center !important;

		.p-type-bold {
			color: var(--Cinza-900, #252b3b);
			font-family: "Inter";
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 0;
		}

		.p-type-green {
			b {
				color: var(--Verde-500, #1cbb8c);
				font-family: "Inter";
				font-size: 13.008px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			color: var(--Verde-500, #1cbb8c);
			font-family: "Inter";
			font-size: 19.512px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 0;
		}

		.p-type-2 {
			color: var(--Cinza-500, #8590a5);
			font-family: "Inter";
			font-size: 13.008px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 0;

			span {
				font-size: 19.512px;
			}
		}
	}

	.boxInfoUser {
		padding: 16px !important;
	}

	.boxAnuncioNew {
		padding: 0 !important;
		margin-bottom: 0 !important;
		background: transparent !important;
		box-shadow: none !important;
		border: none !important;

		.deleteImovel {
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		.userPhoto {
			margin-right: -25px !important;
		}

		.boxUserTableUsers {
			align-items: end;

			.userPhoto {
				margin-right: 16px !important;
			}

			div {
				width: 48px !important;
				height: 48px !important;
			}
		}

		.informationsDatas {
			margin-top: 6px;
			display: flex !important;
			gap: 24px !important;

			.boxInformation {
				p {
					margin: 0;
				}
			}
		}

		.headerUser {
			border: none !important;
			margin: 0 !important;
			padding: 0 !important;
			flex-wrap: nowrap !important;

			.userPhotoNone {
				background-color: #fff;
			}
		}

		.container-imovel-imagem {
			display: flex !important;
			align-items: flex-start;
		}
	}

	.boxUsuarioNegociacao {
		.boxUserTableUsers {
			div {
				margin-right: 24px !important;
			}
		}

		.headerUser {
			align-items: center;
		}
	}

	.col-text-plan {
		display: flex;
		align-items: end;

		div {
			padding-left: 32px;

			p {
				color: var(--Cinza-600, #505d69);
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin: 0;
			}

			b {
				color: var(--Verde-500, #1cbb8c);
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin: 0;
			}
		}
	}

	.col-text-coupon {
		label {
			margin-bottom: 20px;
		}

		b {
			color: var(--Azul-500, #4aa3ff);
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.textemunhaBox {
		display: flex;
		gap: 16px;

		.boxInfos-container {
			background-color: #fff;
		}
	}

	.vistoriaBox {
		.media-box {
			max-width: 168px;
			min-width: 80px;
			position: relative;
			display: flex;
			justify-content: flex-end;
		}

		.media-box img {
			height: 72px;
			width: 72px;
			object-fit: cover;
			position: absolute;
			padding: 4px;
			border-radius: 4px;
			background-color: #fff;
		}

		.medias-more {
			width: 65px;
			height: 65px;
			margin: 4px;
			flex-shrink: 0;
			border-radius: 2px;
			opacity: 0.75;
			background: var(--Roxo-500, #5664d2);
			position: absolute;
			left: 68.2px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.medias-more p {
			color: var(--Cinza-00, #fff);

			/* H4 */
			font-family: Inter;
			font-size: 19.512px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 0px !important;
		}
	}

	.custom-datatableConfigs {
		.p-datatable-wrapper {
			overflow-x: auto;
		}
	}
}

.modalVerContrato {
	width: 100%;
	max-width: 734px;
}

.modalHistorico {
	width: 100%;
	max-width: 734px;
}

.modalCancelarNegociacao {
	width: 100%;
	max-width: 498px;

	h3 {
		margin: 0 0 12px 0;
		color: var(--Cinza-800, #343a40);
		font-family: "Inter";
		font-size: 16.26px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	p {
		margin: 0 0 24px 0;
		color: var(--Cinza-600, #505d69);
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.input-radio-group {
		margin-left: 14px;

		label {
			color: var(--Cinza-600, #505d69);
			font-family: Inter;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

			b {
				color: var(--Vermelho-500, #ff3d60);
				font-family: "Inter";
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}

.even-row {
	background-color: #f1f5f7;
}

.btnGreyNormal {
	border-radius: 4px;
	border: 1px solid var(--Cinza-100, #EEE);
	background: var(--Cinza-50, #F1F5F7);
	display: flex;
	height: 36px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;

	color: var(--Cinza-600, #505D69);
	text-align: center;
	font-family: 'Inter';
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.btnSecundaryNormal {
	border-radius: 4px;
	background: var(--Roxo-500, #5664D2);
	border: 1px solid var(--Roxo-500, #5664D2);

	display: flex;
	height: 36px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 12px;

	color: var(--Roxo-100, #E1E3F7);
	text-align: center;
	font-family: 'Inter';
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}